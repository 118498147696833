#slippage-hades {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 10000;

  .ohm-popover {
    max-width: 500px;
    display: flex;
    flex-direction: column;

    h3 {
      align-self: center;
      justify-self: center;
      width: 75%;
      text-align: center;
    }

    label {
      margin: 33px 0 10px;
    }

    .help-text {
      margin: 10px;
    }
  }

  .mobile .ohm-popover {
    max-width: 400px;
  }

  .slippage-item {
    padding: 0 16px;
    height: 40px;
    line-height: 1;
    font-size: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 12px;
  }

  #slippage,
  #recipient {
    // height: 40px;
    padding: 0 10px;
    font-family: Square;
    font-weight: 500;
    font-size: 20px;
  }

  #recipient {
    font-family: Square;
    font-weight: 500;
    font-size: 20px;
  }

  // .active-btn {
  //   color: red !important;
  // }
  .slippage-inp {

    &:focus,
    &:hover {
      border-width: 1px;
      border: 1px solid #000;
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  // .focus-light {
  //   border: 1px solid #000;
  // }
  // .focus-dark {
  //   border: 1px solid #fff;
  // }
}

@media screen and (max-width: 480px) {
  .slippage-container {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }

  #slippage-hades .slippage-item {
    margin-bottom: 10px !important;
    height: 35px !important;
  }

  .slippage-ipt {
    border-radius: 8px !important;
    height: 35px !important;
  }

  .recipient-ipt {
    border-radius: 8px !important;
    height: 35px !important;
  }

  .last-icon {
    display: flex !important;
  }

  .slippage-precent {
    display: none !important;
  }
}

@media screen and (min-width: 480.1px) {
  .last-icon {
    display: none !important;
  }
}
