.map-line1 {
  width:100%;
  height:100%;
  animation: rotateLine 10s linear infinite;
  transform-origin: center;
}

@keyframes rotateLine {
  0% {
    transform: rotate(0deg);
  }
  
 
  to {
    transform: rotate(-360deg);
  }
}
