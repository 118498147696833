.dapp-topbar {
  width: 100%;
  align-items: center;
  justify-content: center !important;

  .barContainer {
    // width: 100%;
    height: 100px;
    background: #FCD000;
  }

  
}

.tablet .dapp-topbar {
  justify-content: space-between;
}

.app .stake-topbar {
  justify-content: space-between;
}

.pending-txn-container {
  display: flex;
  align-items: center;

  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}

#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}

.caret-down {
  position: absolute;
  right: 0.4rem;
}

.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}

.topbar-link {
  padding: 15px 25px;
  position: relative;
  text-decoration: none !important;

  span {
    // color: red;
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: 10px;
  }

  .topbar-border {
    width: 36px;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: 5px;
    margin-left: -18px;
    border-radius: 10px 10px 0 0;
    display: none;
  }

  &:hover {
    span {
      //   color: red;
      background-image: -webkit-linear-gradient(right, rgba(201, 195, 49, 1), rgba(49, 201, 141, 1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .topbar-border {
      display: block;
    }
  }
}

.active-link {
  // color: rgba(0, 166, 100, 1) !important;

  span {
    //   color: red;
    background-image: -webkit-linear-gradient(right, rgba(201, 195, 49, 1), rgba(49, 201, 141, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .topbar-border {
    display: block;
  }
}

.stake-appbar {
  background: rgba(4, 14, 10, 0.3) !important;
  backdrop-filter: blur(50px) !important;
  padding: 0 !important;
}

.right-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 360px;
    padding: 2px;

    .token-box-container {
      // margin-bottom: 6px;

      .token-box {
        width: 100%;
        height: 69px;
        background: #f2f5f7;
        border: 1px solid #e6e8ec;
        border-radius: 8px;
        padding: 14px 20px;
        margin-bottom: 16px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .add-token-container {
      // margin-bottom: 60px;

      .add-token-box {
        width: 100%;
        height: 50px;
        padding: 14px 20px;
        background: #ffffff;
        border: 1px solid #e6e8ec;
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .invite-container {
      margin-bottom: 50px;

      .invite-title {
        margin-bottom: 10px;
      }

      .invite-link {
        width: 100%;
        height: 60px;
        padding: 15px 16px;
        background: rgba(0, 111, 255, 0.05);
        border: 1px solid #006fff;
        border-radius: 8px;
        white-space: wrap;

        &>p {
          word-break: break-all;
        }
      }
    }
  }
}

.right-drawer-close {
  width: 100%;
  height: 120px;

  .close-box {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}

@media screen and (max-width: 980px) {
  .dapp-topbar {
    .barContainer {
      width:100%;
      padding:0 15px 0 15px ;
      height: 76px;
      border: none;
      border-radius: 0;
      // border-bottom: 1px solid #33303d;
      background: #FCD000;
      backdrop-filter: blur(15px);
    }
  }

  .right-drawer {
    .MuiDrawer-paperAnchorRight {
      padding: 1px;
    }
  }
}

@media screen and (max-width: 680px) {
  .right-drawer {
    .MuiDrawer-paperAnchorRight {
      width: 100%;
    }
  }
}
