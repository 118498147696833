#locales-popper {
  z-index: 1101;
  background-color: #00000003;
  margin-top: 20px !important;
  border-radius: 10px;
  .MuiPaper-root.locales-menu {
    border-radius: 37px;

    border: 1px solid #000;
    background: #fcd000;
    box-shadow: -3px 3px 0px 0px #ECAA00;
    
    & > div {
      // min-width
      // border-radius: 50px;

      // background: linear-gradient(136deg, #32373a 9.64%, #131416 89.75%);
      // box-shadow: 0px 0px 4px 0px #33383c inset;

      // width: 42px;
      // display: block;
      // width: 100%;
      // text-align: center;
      // // margin-bottom: 4px;
      // padding: 6px;
    }
  }
}
