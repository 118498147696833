.bannerCardBg{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 0;
  // background: url('../../assets/images/banner-bg.png') lightgray 50% / cover no-repeat;
  // background: url('../../assets/images/banner-bg.png') no-repeat 100% 100%;
}
